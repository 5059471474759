import { makeAutoObservable } from 'mobx';
import { repositoryGetter, IRepository, TRepoLib } from '@netvision/lib-api-repo';

class Store {
  api = {} as IRepository
  lib?: TRepoLib

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setApiRepository(lib?: TRepoLib) {
    this.api = repositoryGetter(lib?.name)
    this.lib = lib
  }
}

export default new Store()
