import type { IEnumFilter, IEnumLazyFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { IEnumFilterMeta, IEnumRefFilterMeta } from '../../models/IFilterMeta'
import { load, optionsFromEntities } from './utils'

export const transformEnum = (filterMeta: IEnumFilterMeta): IEnumFilter => {
  const { options } = filterMeta
  return {
    type: 'enum',
    field: filterMeta.field,
    options
  }
}

export const transformEnumRef = (filterMeta: IEnumRefFilterMeta): IEnumLazyFilter => {
  const { entityType, labelField = 'title', orderBy } = filterMeta
  return {
    type: 'enumLazy',
    field: filterMeta.field,
    getOptions: () =>
      load({
        limiter: { type: entityType, orderBy, limit: 1000 }
      }).then(optionsFromEntities(labelField))
  }
}
