import React, { FC, memo, useContext, useLayoutEffect, useRef } from 'react'
import { SingleSpaContext } from 'single-spa-react'
import { Widget, WidgetArea, WidgetReceivedProps } from '@netvision/lib-widget-renderer'
import { IEntity } from '@netvision/lib-api-repo'

const AreaName = 'entity-details'

export const EntityDetailsAdapter: FC<{
  entityType: string
  entity?: IEntity
  onClose?: (requiresRefresh: boolean) => void
}> = memo(({ entityType, entity, onClose }) => {
  const { mountChildren, areas } = useContext(SingleSpaContext) as WidgetReceivedProps<any, any>
  const playerContainerRef = useRef<HTMLDivElement | null>(null)
  useLayoutEffect(() => {
    const node = playerContainerRef.current
    const child = getAreasChild(areas, AreaName)
    if (node && child) {
      return mountChildren(node, [
        {
          ...child,
          props: {
            ...child.props,
            entityType,
            entity,
            onClose
          }
        }
      ])
    }
    return undefined
    // eslint-disable-next-line
  }, [])
  return <div style={{ position: 'absolute' }} ref={playerContainerRef} />
})

function getAreasChild(areas: WidgetArea[] | undefined, name: string): Widget | null {
  let child = null
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === name && area.children && area.children.length > 0) {
        child = area.children[0]
      }
    })
  }
  return child
}
