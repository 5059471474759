import { debounce } from 'lodash-es'

export interface IBatchQueue<I, O> {
  add(input: I, reject: () => void, resolve: (output: O) => void): void
}

export interface IQueueEntry<I, O> {
  $id: number
  args: I
  resolve: (output: O) => void
  reject: (err?: unknown) => void
}

export interface IActionInput<I> {
  $id: number
  args: I
}

export interface IActionOutput<O> {
  $id: number
  result: O
}

export interface IActionError {
  $id: number
  err: unknown
}

export const createBatchQueue = <I, O>(
  delay: number,
  action: (args: IActionInput<I>[]) => Promise<Array<IActionOutput<O> | IActionError>>
): IBatchQueue<I, O> => {
  type _Map = Map<IQueueEntry<I, O>['$id'], IQueueEntry<I, O>>
  const idGenerator = createIdGenerator()
  const queue: _Map = new Map()

  const exec = debounce(() => {
    const entries = Array.from(queue.values())
    const inputs = entries.map(({ $id, args }) => ({ $id, args }))
    action(inputs).then((res) => {
      const resMap = new Map(res.map((output) => [output.$id, output]))
      entries.forEach(({ $id, resolve, reject }) => {
        queue.delete($id)
        const output = resMap.get($id)
        if (output) {
          if ('result' in output) {
            resolve(output.result)
          } else {
            reject(output.err)
          }
        } else {
          reject()
        }
      })
    })
  }, delay)

  return {
    add(args, reject, resolve) {
      const entry = {
        $id: idGenerator.next(),
        args,
        reject,
        resolve
      }
      queue.set(entry.$id, entry)
      exec()
      return () => {
        queue.delete(entry.$id)
      }
    }
  }
}

const createIdGenerator = () => {
  let nextId = 0
  return {
    next: () => nextId++
  }
}
