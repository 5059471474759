import { IColumn, isString } from '@netvision/lib-react-table'
import { IMetadata } from '../models/IMetadata'
import { IEntity } from '@netvision/lib-api-repo'
import { selectCell } from './cells'
import { IColumnDescription } from '../models/IViewTable'
import { isBoolean } from 'lodash-es'

export function metadataToColumns(metadata: IMetadata): IColumn<IEntity, string>[] {
  const indexes = new Map()
  metadata.viewTable.order.forEach((key, index) => {
    indexes.set(key, index)
  })
  const { $locale } = metadata
  return Object.entries(metadata.viewTable.columns)
    .filter((entry) => {
      const [, value] = entry as [string, IColumnDescription]
      return !value.disabled
    })
    .map((entry) => {
      const [key, value] = entry as [string, IColumnDescription]
      const col: IColumn<IEntity, string> = {
        field: key,
        header: $locale.attrs?.[key] ?? key,
        body: (rowData: IEntity) => selectCell(rowData, key, value.cell)
      }
      if (isString(value.sortable)) {
        col.sortable = value.sortable
      } else if (value.sortable || value.sortable === undefined) {
        col.sortable = true
      }
      if (isBoolean(value.filterable)) {
        col.filterable = value.filterable
      }
      if (value.required === true) {
        col.requiredColumn = true
      }
      if (value.hidden === true) {
        col.hideByDefault = true
      }
      return col
    })
    .sort((a, b) => {
      const iA = indexes.get(a.field)
      const iB = indexes.get(b.field)
      const isNumA = typeof iA === 'number'
      const isNumB = typeof iB === 'number'
      if (isNumA && isNumB) {
        return iA - iB
      } else if (isNumA && !isNumB) {
        return -1
      } else if (!isNumA && isNumB) {
        return 1
      } else {
        if (a.field < b.field) {
          return 1
        } else if (a.field > b.field) {
          return -1
        } else {
          return 0
        }
      }
    })
}
