/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ITextCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { decodeString } from './helpers/utils'
import React, { useMemo } from 'react'
import { $textWrap } from './helpers/styles'

export function TextCell<T extends {}, K extends string>(props: ICellProps<T, K, ITextCell>) {
  const { rowData, field } = props
  const text = useMemo(() => decodeString(rowData, field, ''), [rowData, field])
  return !text ? null : <span css={$textWrap}>{text}</span>
}
