import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { IMetadata } from '../models/IMetadata'
import { isEqual, upperFirst } from 'lodash-es'
import { isArray, isShape, isString, isUndefined, or } from '@netvision/lib-react-table'
import { useApiRepository } from './useApiRepository'
import { IEntity } from '@netvision/lib-api-repo'

const getEntityMetadataKey = (type: string) => `${PACKAGE_NAME}:entityMetadata:${type}`

export const useMetadata = (types: string[], lang: string) => {
  const [loading, setLoading] = useState(false)
  const [loadingErrorMessage, setLoadingErrorMessage] = useState<null | any>(null)
  const [metadataMap, setMetadataMap] = useState<Map<string, IMetadata | undefined>>(
    () => new Map()
  )
  const prevTypesRef = useRef<string[] | null>(null)
  const { api } = useApiRepository()
  const orderedTypes = useMemo(() => {
    const nextTypes = types.slice().sort()
    const prevTypes = prevTypesRef.current
    if (prevTypes !== null && isEqual(nextTypes, prevTypes)) {
      return prevTypes
    } else {
      return nextTypes
    }
  }, [types])
  prevTypesRef.current = orderedTypes

  useLayoutEffect(() => {
    orderedTypes.forEach((type) => {
      const storageKey = getEntityMetadataKey(type)
      const saved = localStorage.getItem(storageKey)
      if (typeof saved === 'string') {
        let parsed
        try {
          parsed = JSON.parse(saved)
        } catch (e) {
          console.warn('Could not parse saved metadata')
        }
        if (isMetadata(parsed)) {
          const value = parsed
          setMetadataMap((prev) => {
            const map = new Map(prev)
            map.set(type, value)
            return map
          })
        } else {
          localStorage.removeItem(storageKey)
        }
      }
    })
  }, [orderedTypes])
  useEffect(() => {
    let aborted = false
    setLoading(true)

    const localeField = getLocaleField(lang)

    orderedTypes.forEach((type) => {
      api.getEntitiesList<IEntity>({
        limiter: { id: buildMetaId(type), type: 'EntityTypeMetadata' },
        filter: { attrs: [localeField, 'viewTable'].join(',') }
      })
        .then(({ results }) => {
          const res = results?.[0] || null
          if (!hasViewTable(res)) {
            console.error(`EntityMetadata for "${type}" does not have "viewTable" attr`)
            return
          }
          if (!hasLocaleField(localeField)(res)) {
            console.error(`EntityMetadata for "${type}" does not have "${localeField}" attr`)
            return
          }
          const metadata: IMetadata = {
            $entityType: type,
            $locale: res[localeField],
            viewTable: res.viewTable
          }
          localStorage.setItem(getEntityMetadataKey(type), JSON.stringify(metadata))
          if (!aborted) {
            setMetadataMap((prev) => {
              const map = new Map(prev)
              map.set(type, metadata)
              return map
            })
          }
        })
        .catch((error: Error) => {
          setLoadingErrorMessage(error.message)
        })
        .finally(() => {
          if (!aborted) {
            setLoading(false)
          }
        })
    })

    return () => {
      aborted = true
    }
  }, [orderedTypes, lang])
  return [loading, metadataMap, loadingErrorMessage] as const
}

const getLocaleField = (lang: string) => `locale${upperFirst(lang.toLowerCase())}`

const buildMetaId = (type: string) => `EntityTypeMetadata:${type}`

const isLocaleField = isShape({
  typeTitle: or(isString, isUndefined),
  typeTitlePlural: or(isString, isUndefined),
  attrs: or(isShape({}), isUndefined)
})

const isViewTable = isShape({
  order: isArray(isString),
  columns: isShape({})
})

const isMetadata: (v: unknown) => v is IMetadata = isShape({
  $locale: isLocaleField,
  $entityType: isString,
  viewTable: isViewTable
})

const hasLocaleField = (localeField: string) =>
  isShape({
    [localeField]: isLocaleField
  })

const hasViewTable = isShape({
  viewTable: isViewTable
})
