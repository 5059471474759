/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { FC, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { useLocale } from '../../../locale/LocaleProvider'

export const CopyField: FC<{
  width?: number
  value: string
  toShort?: (v: string) => string
  className?: string
}> = ({ width, value, toShort, className }) => {
  const locale = useLocale()
  const ref = useRef<HTMLInputElement>(null)
  const [showShort, setShowShort] = useState(true)
  const displayValue = showShort ? toShort?.(value) ?? value : value
  return (
    <span className={className} css={$copy}>
      <input
        style={width ? { width: `calc(${width}rem/var(--bfs)` } : undefined}
        ref={ref}
        title={value}
        value={displayValue}
        onFocus={() => setShowShort(false)}
        onBlur={() => setShowShort(true)}
        readOnly={true}
      />
      <Button
        className={'p-button-text p-button-secondary'}
        icon={'mdi mdi-18px mdi-content-copy'}
        tooltip={locale.copy}
        onClick={() => {
          if (ref.current) {
            ref.current.value = value
            ref.current.select()
            document.execCommand('copy')
          }
        }}
      />
    </span>
  )
}

const $copy = css`
  display: flex;
  align-items: center;
  > input {
    margin-left: calc(-11rem / var(--bfs));
    padding: calc(5rem / var(--bfs)) calc(10rem / var(--bfs));
    color: var(--text-color-secondary);
    width: calc(120rem / var(--bfs));
    margin-right: var(--spacer-xs);
    border: calc(1rem/ var(--bfs)) solid transparent;
    background: transparent;
    
    :focus {
      outline: none;
      border-color: var(--text-color);
      border-radius: var(--border-radius-sm);
    }
  }
  > button {
    padding: 0 !important;
    height: auto; !important;
    pointer-events: none;
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }
  &:hover > button {
    opacity: 1;
    pointer-events: auto;
  }
`
