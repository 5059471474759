/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { FC, useMemo, useRef } from 'react'
import { useLocale } from '../../../locale/LocaleProvider'
import { Button } from 'primereact/button'

export const JSONField: FC<{ object: {} }> = ({ object }) => {
  const locale = useLocale()
  const ref = useRef<HTMLTextAreaElement>(null)
  const value = useMemo(() => {
    let s = JSON.stringify(object, null, 2)
    if (s.startsWith('{')) {
      s = s.replace('{\n ', '{').replace('\n}', ' }')
    } else if (s.startsWith('[')) {
      s = s.replace('[\n ', '[').replace('\n]', ' ]')
    }
    return s
  }, [object])
  return (
    <span css={$json}>
      <textarea ref={ref} value={value} readOnly={true} rows={3} />
      <Button
        className={'p-button-text p-button-secondary'}
        icon={'mdi mdi-18px mdi-content-copy'}
        tooltip={locale.copy}
        onClick={() => {
          if (ref.current) {
            ref.current.value = value
            ref.current.select()
            document.execCommand('copy')
          }
        }}
      />
    </span>
  )
}

const $json = css`
  display: flex;
  flex-grow: 1;
  position: relative;
  > textarea {
    font-family: monospace;
    font-size: calc(12rem / var(--bfs));
    width: 100%;
    overflow: hidden;
    resize: none;
    margin-left: calc(-11rem / var(--bfs));
    padding: 0 calc(40rem / var(--bfs)) 0 calc(10rem / var(--bfs));
    color: var(--text-color-secondary);
    border: calc(1rem/ var(--bfs)) solid transparent;
    background: transparent;
    outline: none;
    transition: border-color var(--transition-duration) ease;
  }
  &:hover > textarea {
    overflow: auto;
    outline: none;
    border-color: var(--text-color);
    border-radius: var(--border-radius-sm);
    color: var(--text-color);
  }
  > button {
    position: absolute;
    right: calc(var(--spacer-xs) / 2);
    top: calc(var(--spacer-xs) / 2);
    padding: 0 !important;
    height: auto; !important;
    pointer-events: none;
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }
  &:hover > button {
    opacity: 1;
    pointer-events: auto;
  }
`
