import { IColumnDescription } from '../../models/IViewTable'
import { IWidgetLocale } from '../../locale/locale'
import {
  isAutoCompleteFilterMeta,
  isEnumFilterMeta,
  isEnumRefFilterMeta,
  isIdFilterMeta,
  isRangeFilterMeta,
  isSearchFilterMeta,
  isTextFilterMeta,
  isTimeFilterMeta
} from '../../models/IFilterMeta'
import { transformText } from './text'
import { transformSearch } from './search'
import { transformRange } from './range'
import { transformEnum, transformEnumRef } from './enum'
import { transformTime } from './time'
import { transformAutocomplete } from './autocomplete'
import {
  IRefCell,
  isDateTimeCell,
  isIdCell,
  isRefCell,
  isTextCell,
  isUrlCell
} from '../../models/ICell'
import { transformId } from './id'

export const selectFilter = (
  entityType: string,
  field: string,
  columnDescription: IColumnDescription,
  locale: IWidgetLocale
) => {
  const { filter, cell } = columnDescription
  
  if (filter === null) {
    return null
  } else if (isIdFilterMeta(filter)) {
    return transformId(entityType)
  } else if (isTextFilterMeta(filter)) {
    return transformText(filter, locale)
  } else if (isSearchFilterMeta(filter)) {
    return transformSearch(filter, locale)
  } else if (isRangeFilterMeta(filter)) {
    return transformRange(filter, locale)
  } else if (isEnumFilterMeta(filter)) {
    return transformEnum(filter)
  } else if (isEnumRefFilterMeta(filter)) {
    return transformEnumRef(filter)
  } else if (isTimeFilterMeta(filter)) {
    return transformTime(filter)
  } else if (isAutoCompleteFilterMeta(filter)) {
    return transformAutocomplete({ ...filter, labelField: field }, locale)
  } else {
    if (isIdCell(filter)) {
      return field === 'id' ? transformId(entityType) : transformText({ type: 'text' }, locale)
    } else if (isRefCell(cell)) {
      const { typeStatic, idField, queryField, labelField } = cell as IRefCell
      return typeStatic
        ? transformSearch(
            {
              type: 'searchRef',
              field: idField,
              entityType: typeStatic,
              queryField: queryField || 'title',
              labelField: labelField || 'title'
            },
            locale
          )
        : null
    } else if (field === 'title' && isTextCell(cell)) {
      return transformAutocomplete({ type: 'autocompleteRef', entityType }, locale)
    } else if (isTextCell(cell) || isUrlCell(cell)) {
      return transformText({ type: 'text' }, locale)
    } else if (isDateTimeCell(cell)) {
      return transformTime({ type: 'dateTime', max: Number(cell.max) || 'now' })
    } else {
      return null
    }
  }
}
