import { IWidgetLocale } from '../../locale/locale'
import type { IAutoCompleteFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { IAutoCompleteFilterMeta } from '../../models/IFilterMeta'
import { searchByField } from './utils'
import { get } from 'lodash-es'

export const transformAutocomplete = (
  filterMeta: IAutoCompleteFilterMeta,
  locale: IWidgetLocale
): IAutoCompleteFilter => {
  const { entityType, minLength = 1, labelField = 'title', limit = 20 } = filterMeta
  return {
    type: 'autocomplete',
    field: filterMeta.field,
    placeholder: locale.search,
    minLength: minLength,
    searchOptions: (query: string) =>
      searchByField(entityType, query, labelField, limit).then((res) =>
        res.map((en) => get(en, labelField) as string)
      )
  }
}
