import React, { FC, Fragment, useContext, useLayoutEffect, useMemo, useRef } from 'react'
import { SingleSpaContext } from 'single-spa-react'
import { Node, WidgetReceivedProps } from '@netvision/lib-widget-renderer'
import { useLocationProp } from '../hooks/useLocationProp'
import { isShape, isString } from '@netvision/lib-react-table'
import { useForceLoad } from '../hooks/useForceLoad'

export const ExtraButtonsAdapter = () => {
  const pathname = useLocationProp('pathname')
  const { areas } = useContext(SingleSpaContext) as WidgetReceivedProps<any, any>
  const nodes = useMemo(
    () =>
      (areas ?? []).reduce((acc, area) => {
        if (area.name === 'extra-buttons') {
          acc.push(
            ...area.children.filter((ch) => {
              if (hasPath(ch)) {
                return pathname.startsWith(ch.path)
              }
              return true
            })
          )
        }
        return acc
      }, [] as Node[]),
    [areas, pathname]
  )
  if (nodes.length === 0) {
    return null
  } else {
    return (
      <Fragment>
        {nodes.map((node, index) => (
          <ExtraButton key={index} node={node} />
        ))}
      </Fragment>
    )
  }
}

const hasPath = isShape({
  path: isString
})

const ExtraButton: FC<{ node: Node }> = ({ node }) => {
  const { mountChildren } = useContext(SingleSpaContext) as WidgetReceivedProps<any, any>
  const divRef = useRef<HTMLDivElement | null>(null)
  const forceLoad = useForceLoad()
  useLayoutEffect(() => {
    const div = divRef.current
    if (!div) return
    let child = node
    if (child.type === 'widget') {
      child = {
        ...child,
        props: {
          ...child.props,
          forceLoad: forceLoad
        }
      }
    }
    return mountChildren(div, [child])
  }, [mountChildren, node, forceLoad])
  return <div ref={divRef} />
}
