import { setPublicPath } from 'systemjs-webpack-interop'
setPublicPath(PACKAGE_NAME)

import React from 'react'
import ReactDOM from 'react-dom'
import { Root } from './Root'
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOM: ReactDOM as any,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props)
    return React.createElement('div')
  }
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount
export const update = lifecycles.update
