/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { decodeUrl } from './utils'
import React, { FC, useMemo } from 'react'

export const ImageField: FC<{ object: object; field: string; className?: string }> = ({
  object,
  field,
  className
}) => {
  const url = useMemo(() => decodeUrl(object, field, null), [object, field])
  return !url?.href ? null : <img className={className} src={url.href} alt={''} css={$css} />
}

const $css = css`
  display: block;
  object-fit: contain;
  height: calc(30rem / var(--bfs));
  width: calc(30rem / var(--bfs));
`
