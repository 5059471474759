import React, { useEffect, useState } from 'react';
import { history, History } from '@netvision/lib-history';

export const useLocationProp = <T extends keyof History['location']>(prop: T): History['location'][T] => {
  const [value, setValue] = useState(history.location[prop]);
  useEffect(() => {
    setValue(history.location[prop]);
    return history.listen((location) => {
      setValue(location[prop]);
    });
  }, [prop]);
  return value;
};
