import React, { useMemo } from 'react'
import { IUrlCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { decodeUrl } from './helpers/utils'
import { CopyField } from './helpers/CopyField'

export function UrlCell<T extends {}, K extends string>(props: ICellProps<T, K, IUrlCell>) {
  const { rowData, field } = props
  const data = useMemo(() => decodeUrl(rowData, field, null), [rowData, field])
  return !data || !data.href ? null : (
    <CopyField
      width={180}
      value={data.href}
      toShort={() => (data.url ? data.url.host : data.href.slice(0, 9))}
    />
  )
}
