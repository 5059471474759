import React from 'react'
import { IBooleanCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { Checkbox } from 'primereact/checkbox'
import { get, isBoolean } from 'lodash-es'

export function BooleanCell<T extends {}, K extends string>(props: ICellProps<T, K, IBooleanCell>) {
  const { rowData, field } = props
  const value = get(rowData, field)
  return isBoolean(value) ? <Checkbox checked={value} readOnly disabled /> : null
}
