import React, { createContext, FC, useContext, useState } from 'react'
import { IEntity } from '@netvision/lib-api-repo'

export type ISetModal = (params: null | IModalEntityDetails | IModalDelete) => void
const setModalCtx = createContext<ISetModal>(null!)

export const useSetModal = () => useContext(setModalCtx)

export const useSetModalState = () => useState<null | IModalEntityDetails | IModalDelete>(null)

export const SetModalProvider: FC<{ value: ISetModal }> = ({ value, children }) => {
  return <setModalCtx.Provider value={value}>{children}</setModalCtx.Provider>
}

type IModalEntityDetails = {
  type: 'entityDetails'
  entityType: string
  entity?: IEntity
}

type IModalDelete = {
  type: 'delete'
  entityType: string
  entities: IEntity[]
}
