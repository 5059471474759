/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useCallback, useMemo, useRef, Fragment, useState, FC, useEffect } from 'react'
import {
  INgsiOnLoadEvent,
  simpleObservable,
  Table,
  useObservable
} from '@netvision/lib-react-table'
import { IEntity } from '@netvision/lib-api-repo'
import { IMetadata } from '../models/IMetadata'
import { metadataToColumns } from './metadataToColumns'
import { metadataToFilterDescription } from './metadataToFilterDescription'
import { useLocale } from '../locale/LocaleProvider'
import { useEntityCacheReset } from '../hooks/useEntity'
import { ToastProvider, useToastRef } from '../hooks/useToastRef'
import { ForceLoadProvider } from '../hooks/useForceLoad'
import { DeleteModal } from './DeleteModal'
import { usePermittedScopes } from '../hooks/usePermissions'
import { upperFirst } from 'lodash-es'
import { useLoadingObs } from '../providers/LoadingObsProvider'
import { ExtraButtonsAdapter } from './ExtraButtonsAdapter'
import { EntityDetailsAdapter } from './EntityDetailsAdapter'
import { Button } from 'primereact/button'
import { SetModalProvider, useSetModal, useSetModalState } from '../hooks/useSetModal'
import { useApiRepository } from '../hooks/useApiRepository'

export function Main(props: {
  title: string
  entityType: string
  tabs: Array<{ title: string; link: string; entityType: string }>
  metadata?: IMetadata
  loadingErrorMessage: string | null
}) {
  const locale = useLocale()
  const loadingObs = useLoadingObs()
  const resetEntityCache = useEntityCacheReset()
  const { metadata, tabs, entityType, title, loadingErrorMessage } = props
  const { lib } = useApiRepository()

  const permittedScopes = usePermittedScopes(
    useMemo(
      () => parseCommands(metadata?.viewTable.commands).map((command) => `${command}${entityType}`),
      [metadata, entityType]
    ),
    entityType
  )

  const permissionType = permittedScopes.get(entityType)

  const canCreate = permissionType?.has(`Create${entityType}`)
  const canUpdate = permissionType?.has(`Update${entityType}`)
  const canDelete = permissionType?.has(`Delete${entityType}`)

  const defaultSortField = metadata?.viewTable.defaultSort?.field ?? null
  const defaultSortOrder = metadata?.viewTable.defaultSort?.order ?? 1
  const defaultPage = useMemo(() => {
    return {
      rows: 10,
      rowsPerPage: [10, 25, 50, 100],
      sortField: defaultSortField,
      sortOrder: defaultSortOrder
    }
  }, [defaultSortField, defaultSortOrder])
  const forceLoadRef = useRef<(() => void) | null>(null)
  const forceLoad = useCallback(() => {
    forceLoadRef.current?.()
  }, [])

  const columns = useMemo(() => {
    if (!metadata) {
      return []
    }
    const columns = metadataToColumns(metadata)
    if (canUpdate) {
      const prev = columns[0].body
      columns[0].body = (rowData) => {
        const res = prev?.(rowData)
        if (res) {
          return <FirstColumnWrapper rowData={rowData} type={entityType}>{res}</FirstColumnWrapper>
        } else {
          return null
        }
      }
    }
    return columns
  }, [canUpdate, metadata])
  const filterDescription = useMemo(() => {
    if (!metadata) {
      return {}
    }
    return metadataToFilterDescription(entityType, metadata, locale)
  }, [entityType, metadata, locale])

  const dataSource = useMemo(
    () => ({
      type: entityType,
      onLoad: (e: INgsiOnLoadEvent<IEntity, any>) => {
        resetEntityCache()
        forceLoadRef.current = e.forceLoad
      }
    }),
    [resetEntityCache, entityType]
  )

  const [modalParams, setModal] = useSetModalState()
  const [selectObs] = useState(() => simpleObservable<IEntity[]>([]))
  const selectedRows = useObservable(selectObs)

  const canSelect = canDelete

  const additionalControls = (
    <Fragment>
      {canSelect && selectedRows.length > 0 && <SelectedAmount number={selectedRows.length} />}
      {canDelete && selectedRows.length > 0 && (
        <Button
          className={'p-button-outlined p-button-danger'}
          label={locale.delete}
          onClick={() => setModal({ type: 'delete', entityType, entities: selectedRows })}
        />
      )}
      <ExtraButtonsAdapter />
      {entityType && canCreate && (
        <Button
          label={locale.add}
          onClick={() => {
            setModal({ type: 'entityDetails', entityType })
          }}
        />
      )}
    </Fragment>
  )
  
  return (
    <ToastProvider position={'bottom-left'}>
      <SetModalProvider value={setModal}>
        <ForceLoadProvider value={forceLoad}>
          <Table
            lib={lib}
            title={title}
            tabs={tabs}
            dataKey={'id'}
            dataSource={dataSource}
            columns={columns}
            defaultPage={defaultPage}
            requireSortField={false}
            filterDescription={filterDescription}
            preferencesLocalStorageKey={
              metadata && `${PACKAGE_NAME}:tableSettings:${metadata?.$entityType}`
            }
            additionalControls={additionalControls}
            loadingObs={loadingObs}
            selectObs={canSelect ? selectObs : undefined}
          />
          {modalParams?.type === 'delete' && (
            <DeleteModal
              entities={modalParams?.entities}
              entityType={modalParams?.entityType}
              onClose={(deleted) => {
                setModal(null)
                if (deleted && deleted.size > 0) {
                  selectObs.set(selectObs.get().filter((en) => !deleted.has(en.id)))
                  forceLoad()
                }
              }}
            />
          )}
          {modalParams?.type === 'entityDetails' && (
            <EntityDetailsAdapter
              entityType={modalParams?.entityType}
              entity={modalParams?.entity}
              onClose={(requiresRefresh) => {
                setModal(null)
                requiresRefresh && forceLoad()
              }}
            />
          )}
          <ErrorSpace message={loadingErrorMessage} />
        </ForceLoadProvider>
      </SetModalProvider>
    </ToastProvider>
  )
}

const ErrorSpace = ({ message }: { message: string | null }) => {
  const toast = useToastRef()
  const locale = useLocale()
  useEffect(() => {
    if (message) {
      toast.current?.show({
        severity: 'error',
        summary: locale.error,
        detail: message,
        life: 5000
      })
    }
  }, [message])
  return (<div />)
}

const SelectedAmount: FC<{ number: number }> = ({ number }) => {
  const locale = useLocale()
  const [prefix, suffix] = useMemo(() => {
    const str = locale.selectedTemplate
    const token = '{{}}'
    const index = str.indexOf(token)
    return [str.slice(0, index), str.slice(index + token.length)]
  }, [locale])
  return (
    <span css={$selectedAmount}>
      {prefix}
      <b>{number}</b>
      {suffix}
    </span>
  )
}
const $selectedAmount = css`
  font-weight: 500;
  font-size: calc(14rem / var(--bfs));
  color: var(--text-color-secondary);
  & > b {
    font-weight: 500;
    color: var(--text-color);
  }
`

const FirstColumnWrapper: FC<{ rowData: IEntity, type: string }> = ({ rowData, type, children }) => {
  const setModal = useSetModal()
  return (
    <span css={$firstColumn}>
      {children}
      <span data-role={'details'}>
        <Button
          icon={'mdi mdi-18px mdi-launch'}
          className={'p-button-text p-button-secondary'}
          onClick={() =>
            setModal({ type: 'entityDetails', entity: rowData, entityType: rowData.type || type })
          }
        />
      </span>
    </span>
  )
}

const $firstColumn = css`
  display: flex;
  align-items: center;

  > span[data-role='details'] {
    height: 0;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--transition-duration) ease;
  }

  tr:hover & > span[data-role='details'] {
    opacity: 1;
    pointer-events: auto;
  }
`

type IAvailableCommand = 'Create' | 'Update' | 'Delete'

const parseCommands = (v: unknown): Array<IAvailableCommand> => {
  if (v === null) {
    return []
  } else if (Array.isArray(v)) {
    return [...new Set(v)]
      .map((v) => upperFirst(v.toLowerCase()))
      .filter((v): v is IAvailableCommand => ['Create', 'Update', 'Delete'].includes(v))
  } else {
    return ['Create', 'Update', 'Delete']
  }
}
