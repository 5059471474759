import React, { useMemo } from 'react'
import { IPasswordCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { decodeString } from './helpers/utils'

export function PasswordCell<T extends {}, K extends string>(
  props: ICellProps<T, K, IPasswordCell>
) {
  const { rowData, field } = props
  const text = useMemo(() => decodeString(rowData, field, ''), [rowData, field])
  return !text ? null : <span>{text}</span>
}
